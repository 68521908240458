import * as React from "react";

import Layout from "../components/Layout";

const ShippingPolicy = () => {
  return (
    <Layout
      pageTitle="Refund Policy"
      pageDescription="Refund Policy for the Gatsby Store"
    >
      <section className="policy-section">
        <div className="container">
          <h1>Shipping Policy</h1>
          <p>
            Welcome to our shipping policy page. We currently only provide
            shipping services within India.
          </p>

          <h2>Shipping Methods</h2>
          <p>We offer the following shipping method:</p>
          <ul>
            <li>
              Standard Shipping: Estimated delivery within 5-7 business days.
            </li>
          </ul>

          <h2>In-Store Pickup</h2>
          <p>
            You can also choose to pick up your order from our store location.
            Please contact us to schedule a pickup time.
          </p>

          <h2>Shipping Details</h2>
          <p>
            After placing succesful order, we'll contact you to confirm whether
            you want to pick up your order from our store or have it delivered
            to your shipping address. And for delivery, we'll need the following
            information:
          </p>
          <ul>
            <li>Name</li>
            <li>Shipping Address (for delivery)</li>
            <li>Contact Number</li>
            <li>Email Address</li>
          </ul>

          <h2>Contact Information</h2>
          <p>Please feel free to reach out to us via the following channels:</p>
          <ul>
            <li>Phone: +91 94652 22255</li>
            <li>WhatsApp: +91 94652 22255</li>
            <li>
              Email:{" "}
              <a href="mailto:info@computerhut.in">info@computerhut.club</a>
            </li>
          </ul>

          <h2>Order Processing</h2>
          <p>
            Once we receive your order details, we will contact you to confirm
            the order and provide further instructions for shipping.
          </p>

          <h2>Shipping Policy Updates</h2>
          <p>
            We reserve the right to update our shipping policy at any time. Any
            changes to our policy will be posted on this page.
          </p>

          <h2>Questions?</h2>
          <p>
            If you have any questions about our shipping policy or need
            assistance with your order, please don't hesitate to contact us.
          </p>
        </div>
      </section>
    </Layout>
  );
};

export default ShippingPolicy;
